import { View } from '@neos/app'
import { OpenModeType } from '@neos/shared'

export const views: View[] = [
  {
    name: 'TmsAscOrderTrackingOperatingEventUI',
    openMode: OpenModeType.newFrame,
    component: () => import('./views/TmsAscOrderTrackingOperatingEventUI/TmsAscOrderTrackingOperatingEventUI.vue')
  },
  {
    name: 'TmsAscOrderTrackingUI',
    openMode: OpenModeType.newFrame,
    component: () => import('./views/TmsAscOrderTrackingUI/TmsAscOrderTrackingUI.vue')
  },
]